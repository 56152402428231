import React from "react"
import { Row, Container, Col } from 'react-bootstrap';
import { Link } from "@StarberryUtils";
import Layout from "@Components/layout";
import SEO from "@Components/seo";

import { graphql } from 'gatsby'
import get from 'lodash/get'
import GetURL from '@Components/common/site/get-url';
class Sitemap extends React.Component {

  render() {

    const Menus = get(this, 'props.data.glstrapi.menuHeaders')
    // const AreaGuides = get(this, 'props.data.glstrapi.areaGuides')


    return (
      <Layout css={true}>
        <SEO title="Sitemap" description="" />
        <div className="sitemap-content sitemap">
          <Container>
            <Row>
              <Col
                lg={12}
                md={{
                  span: 12
                }}
              >
                <h1 style={{display:'none'}}>Sitemap</h1>
                <ul className="sitemap-main-list">
                  {Menus.map((node, key) => {
                    return <>
                      <li className="list-single-child">
                      <h4><GetURL label={node.Parent_Label} alias={node.Parent_URL?.Alias}  /></h4>
                        {node.Sub_Menus?.length !== 0 &&
                          <ul className="sub-menu sitemap-sub-menu-list">
                            {node.Sub_Menus.map((Sub_Menus, i) => (
                              <>
                                <li>
                                <GetURL label={Sub_Menus?.Parent_Label} alias={Sub_Menus.Parent_URL?.Alias}  />                                
                                  {Sub_Menus.Parent_URL.Alias !== 'areas-we-cover' && Sub_Menus.Add_Sub_Menus?.length > 0 &&
                                    <ul>
                                      {Sub_Menus.Add_Sub_Menus.map((Add_Sub_Menus, i) => (
                                        <li key={i}>
                                          <GetURL label={Add_Sub_Menus.Label} alias={Add_Sub_Menus.URL?.Alias}  />
                                        </li>
                                      ))}
                                    </ul>
                                  }
                                  {/* {Sub_Menus.Parent_URL.Alias === 'areas-we-cover' &&
                                    <ul>
                                      {AreaGuides.map((area, i) => (
                                        area?.Publish === true && area.Title !== null && area.URL ?
                                        <li key={i}>
                                          <h6><GetURL label={area.Title} alias={area.URL}  />
                                          </h6>
                                        </li>
                                        : null
                                      ))}
                                    </ul>
                                  } */}
                                </li>
                              </>
                            ))}
                          </ul>
                        }
                      </li>

                    </>
                  })}
                      <li><h4><Link to="/terms-and-conditions">Terms and Conditions</Link></h4></li>
                      <li><h4><Link to="/privacy-policy">Privacy Policy</Link></h4></li>
                      <li><h4><Link to="/sitemap">Sitemap</Link></h4></li>
                      <li><h4><Link to="/cookie-policy">Cookie Policy</Link></h4></li>
                      <li><h4><Link to="/payment-diversion-fraud">Payment Diversion Fraud</Link></h4></li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>

      </Layout>
    )
  }
}

export default Sitemap


export const pageQuery = graphql`
  query Sitemap{

    glstrapi {
      menuHeaders(publicationState: LIVE, sort:"Sort:asc") {
        Parent_Label
        Sub_Menus {
          Add_Sub_Menus {
            Label
            URL {
              Alias
            }
          }
          Parent_Label
          Parent_URL {
            Alias
          }
        }
        Publish
        Parent_URL {
          Alias
        }
      }

      areaGuides(publicationState: LIVE, sort:"published_at:desc") {
        Title
        URL
        Publish
      }

    }

   

  }
`
